import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FiSend } from 'react-icons/fi';

import {
    faAngleLeft,
    faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import { MdVerifiedUser } from 'react-icons/md';
import firebase from "firebase";
import { HiHeart, HiOutlineBookmark, HiOutlineHeart, HiOutlineChat, HiOutlineChatAlt, HiOutlineChatAlt2, HiBookmark, HiBookmarkAlt } from 'react-icons/hi'
import { database } from "../config";
import ReadComments from './ReadComments';
import { Button, Form, Modal } from 'react-bootstrap';
import {
    EmailShareButton,
    FacebookShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";
import {
    EmailIcon,
    FacebookIcon,
    TelegramIcon,
    TwitterIcon,
    WhatsappIcon,
} from "react-share";
function FeaturedCard(props) {
    const [likes, setLikes] = useState(0)
    const [comments, setComments] = useState(0)
    const [liked, setLiked] = useState(false)
    const [saved, setSaved] = useState(false)
    const [userUid, setUserUid] = useState(null);
    const [showReviewModal, setShowReviewModal] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false)

    const [comment, setComment] = useState("")
    const [profileData, setProfileData] = useState(null)
    const url = "https://gallerina.netlify.app/product?" + props.data.key

    const submitComent = (carKey) => {
        database.ref("comments").child(props.data.key).push({
            senderUid: userUid,
            carKey: carKey,
            comment: comment,
            name: profileData[0].name,
        });
        setComment("")
    };

    useEffect(() => {
        firebase.auth().onAuthStateChanged(function (user) {
            if (!user) {
                console.log('not logged in')
            } else {
                setUserUid(user.uid);
                database.ref('User_likes').child(user.uid).orderByChild("liked").equalTo(props.data.key).on('value', (snapshot) => {
                    snapshot.val() && setLiked(true)
                })
                database.ref('User_saved').child(user.uid).orderByChild("saved").equalTo(props.data.key).on('value', (snapshot) => {
                    snapshot.val() && setSaved(true)
                })
            }
        })
        database.ref('product_likes').child(props.data.key).on('value', (snapshot) => {
            snapshot.val() && setLikes(snapshot.val().like);
        })
        database.ref("comments").child(props.data.key).on("value", (snapshot) => {
            snapshot.val() && setComments(snapshot.numChildren());
        })
        userUid && database.ref("My-Profile").orderByChild("userUid").equalTo(userUid).on('value', (snapshot) => {
            const items = [];
            snapshot.forEach((childSnapshot) => {
                var childData = childSnapshot.val();
                items.push(childData);
            });
            setProfileData(items)
        });
        return () => {
            setComments(0)
            setLikes(0)
        }
    }, [])
    return (
        <div className="featured_card_container" >
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <div style={{ width: '100%', display: 'flex', alignItems: 'center', background: '#00000000', padding: '13px 10px' }}>
                    <img
                        src={props.data.userData[0].thumbnail}
                        style={{ height: '36px', width: '36px', borderRadius: '18px', marginRight: '10px' }}
                    />
                    <p style={{ color: '#fff', margin: '0' }}>{props.data.userData[0].name}</p>
                    <div style={{ flex: '1' }} />
                    {
                        props.data.userData[0].verified ?
                            <div style={{ fontSize: '.9em', color: '#20c997', padding: '5px 10px', display: 'flex', alignItems: 'center', borderRadius: '10px', borderRadius: '10px' }}>
                                <MdVerifiedUser style={{ fontSize: '17px', marginRight: '5px' }} />verified Org.
                            </div> : <></>
                    }
                </div>
                <Link style={{ width: '100%' }} to={{ pathname: '/organization', search: `?${props.data.key}`, state: { fromDashboard: true } }}>
                    <img
                        src={props.data.imageOneURL}
                        style={{ borderRadius: '0px', height: '260px', width: '100%' }}
                    />
                </Link>
                <div style={{ width: '100%', color: '#fff', padding: '15px' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {
                                liked ?
                                    <HiHeart style={{ fontSize: '30px', color: 'red', marginRight: '5px' }}
                                        onClick={async (e) => {
                                            e.stopPropagation();
                                            await database.ref('product_likes').child(props.data.key).set({
                                                like: firebase.database.ServerValue.increment(-1)
                                            })
                                            await database.ref('User_likes').child(userUid).orderByChild("liked").equalTo(props.data.key).on('value', (snapshot) => {
                                                snapshot.val() && database.ref('User_likes').child(userUid).child(Object.keys(snapshot.val())[0]).set({})
                                            })
                                            console.log("#########UNLIKED#########");
                                            setLiked(false)
                                        }} ></HiHeart>
                                    :
                                    <HiOutlineHeart style={{ fontSize: '30px', marginRight: '5px' }}
                                        onClick={async (e) => {
                                            e.stopPropagation();
                                            await database.ref('product_likes').child(props.data.key).set({
                                                like: firebase.database.ServerValue.increment(1)
                                            })
                                            await database.ref('User_likes').child(userUid).push({
                                                liked: props.data.key,
                                                product: props.data
                                            })
                                            console.log("#########LIKED#########");
                                            setLiked(true)
                                        }} />
                            }
                            <HiOutlineChat
                                style={{ marginLeft: '15px', fontSize: '30px', color: '#fff', marginRight: '5px' }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowReviewModal(true)
                                }}
                            />
                            {comments}

                            <FiSend style={{ fontSize: '27px', color: '#fff', marginLeft: '20px' }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowShareModal(true)
                                }} />
                            <div style={{ flex: '1' }}></div>
                            {
                                saved ?
                                    <HiBookmark style={{ fontSize: '30px', color: '#fff' }}
                                        onClick={async (e) => {
                                            e.stopPropagation();
                                            await database.ref('User_saved').child(userUid).orderByChild("saved").equalTo(props.data.key).on('value', (snapshot) => {
                                                snapshot.val() && database.ref('User_saved').child(userUid).child(Object.keys(snapshot.val())[0]).set({})
                                            })
                                            console.log("#########unsaved#########");
                                            setSaved(false)
                                        }} />
                                    :
                                    <HiOutlineBookmark style={{ fontSize: '30px', color: '#fff' }}
                                        onClick={async (e) => {
                                            e.stopPropagation();
                                            await database.ref('User_saved').child(userUid).push({
                                                saved: props.data.key,
                                                product: props.data
                                            })
                                            console.log("#########saved#########");
                                            setSaved(true)
                                        }} />
                            }
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                                <>
                                    <span >{likes}{' '}{likes > 0 ? 'Likes' : 'Like'}{' '}{props.data.address !== "" && <><FontAwesomeIcon style={{ marginLeft: '10px' }} icon={faMapMarkerAlt}></FontAwesomeIcon> {String(props.data.address).slice(0, 20)} </>}</span>
                                </>
                                <span >{props.data.title}</span>
                            </div>
                            <div style={{ flex: '1', minWidth: '10px' }}></div>
                        </div>
                    </div>
                    <span style={{ fontSize: '1.3em', alignSelf: 'center', color: '#fff', borderRadius: '8px', padding: '2px 2px' }}>With a goal of ${Number(props.data.sellingPrice).toLocaleString('en')}</span>
                </div>
            </div>
            <Modal className='comment_section' animation={false} show={showReviewModal} onHide={() => { setShowReviewModal(false) }} style={{ paddingBottom: '20px' }}>
                <div className='topbar_container'>
                    <div onClick={() => { setShowReviewModal(false) }}>
                        <FontAwesomeIcon icon={faAngleLeft} style={{ padding: '0px 27px', fontSize: '1.6em', color: '#fff' }} />
                    </div>
                    <h2 style={{ color: '#fff', fontSize: '1.1em', fontWeight: '500', margin: '0' }}>
                        Comment section
                    </h2>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', padding: '10px' }}>
                    <Form.Control type="text" value={comment} placeholder="Write here..." required onChange={(e) => setComment(e.target.value)} />
                    <Button onClick={() => { comment !== "" && submitComent(props.data.key) }}>send</Button>
                </div>
                <ReadComments propertyKey={props.data.key} />
            </Modal>
            <Modal className='share' animation={false} show={showShareModal} onHide={() => { setShowShareModal(false) }}  >
                <div style={{ display: 'flex', flexDirection: 'row', padding: '10px', justifyContent: 'space-between' }}>
                    <EmailShareButton subject='check out this organization' body={url} ><EmailIcon size={50} round={true} style={{ margin: '5px' }} /></EmailShareButton>
                    <FacebookShareButton quote={'check out this organization'} url={url} hashtag="#autovid" ><FacebookIcon size={50} round={true} style={{ margin: '5px' }} /></FacebookShareButton>
                    <TelegramShareButton title={'check out this organization'} url={url} ><TelegramIcon size={50} round={true} style={{ margin: '5px' }} /></TelegramShareButton>
                    <TwitterShareButton title={'check out this organization'} url={url} hashtags={['#autovid']} ><TwitterIcon size={50} round={true} style={{ margin: '5px' }} /></TwitterShareButton>
                    <WhatsappShareButton title={'check out this organization'} url={url}><WhatsappIcon size={50} round={true} style={{ margin: '5px' }} /></WhatsappShareButton>
                </div>
            </Modal>
        </div>
    );
}

export default FeaturedCard