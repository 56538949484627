import React, { useEffect, useState } from "react";
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax 
import { FaExpandArrowsAlt, FaMapMarkerAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom'
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { database } from "../../config";


const CreateMap = (props) => {
    mapboxgl.accessToken = 'pk.eyJ1IjoibWVudXRvayIsImEiOiJjbGMzeG1kNHgwbm9lNDFuemkzaXduazgxIn0.alLoPOMYiPcZtHmA9EaLZQ';



    useEffect(() => {
        const map = new mapboxgl.Map({
            container: "mapContainer",
            style: "mapbox://styles/mapbox/navigation-night-v1",
            center: props.lngLat,
            zoom: 6,
            interactive: false
        });
        map.on('click', (e) => {
            map.flyTo({
                center: e.lngLat
            });
        });

        // database
        //     .ref("cars")
        //     .limitToLast(5)
        //     .on("value", (snapshot) => {
        //         const items = [];
        //         snapshot.forEach((childSnapshot) => {
        //             var childKey = childSnapshot.key;
        //             var data = childSnapshot.val();
        //             items.push({
        //                 key: childKey,
        //                 title: data.title,
        //                 imageOneURL: data.imageOneURL,
        //                 bedrooms: data.bedrooms,
        //                 bathrooms: data.bathrooms,
        //                 parking: data.parkingSpace,
        //                 category: data.category,
        //                 sellingPrice: data.sellingPrice,
        //                 lngLat: data.lngLat,
        //             });
        //         });
        //         items.map((item) => {
        //             const marker = new mapboxgl.Marker({ color: 'red' })
        //                 .setLngLat(item.lngLat)
        //                 .addTo(map)
        //             marker.getElement().addEventListener('click', () => {
        //                 console.log('clicked');
        //             });
        //         })
        //     })

    }, []);
    return (
        <Link to="/map-view">
            <div id="mapContainer" className="map" style={{ height: '100%', width: '100%' }}>
                <div className="mapboxgl-ctrl mapboxgl-ctrl-group"
                    onClick={(e) => { console.log('clik'); }}
                    style={{ width: 'min-content', height: 'min-content', background: '#404040', borderRadius: '10', padding: '0px', margin: '10px', position: 'absolute', zIndex: '99', right: 0, bottom: 0 }} >
                    <Link to="/map-view">
                        <button className="mapboxgl-ctrl-geolocate" >
                            <FaExpandArrowsAlt fontSize={'18px'} style={{ color: '#ccc' }} />
                        </button>
                    </Link>
                </div>
            </div>
        </Link>
    );
};

export default CreateMap;