import React, { useState, useEffect } from "react";
import { Card, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { database } from "../config";


export const categories = [

  {
    name: `Retirement fund organizations`,
    url: 'https://www.allianztravelinsurance.com/v_1427380795820/media/travel/seniors/senior-singles-travel.jpg',
    linkTo: '/map-view',
  },
  {
    name: 'Social and recreational clubs',
    url: 'https://www.canterbury.ac.nz/business/bachelor-of-commerce/clubs-and-societies/UCom-display-at-2017-UC-Clubs-day_1897737650699543766.jpg',
    linkTo: '/map-view',
  },
  {
    name: `Veterans' organizations`,
    url: 'https://militarybase.net/wp-content/uploads/2020/04/vso-veteran-service-organizations.jpg',
    linkTo: '/map-view',
  },
  {
    name: 'Charitable organizations',
    url: 'https://cdn2.f-cdn.com/contestentries/2069462/55699415/624b6ab30ecd6_thumb900.jpg',
    linkTo: '/map-view',
  },
  {
    name: 'Social advocacy groups',
    url: 'https://www.macalester.edu/news/wp-content/uploads/sites/5/2021/01/iStock-1182252827.jpg',
    linkTo: '/map-view',
  },
  {
    name: 'Religious and apostolic associations',
    url: 'https://www.gannett-cdn.com/-mm-/7faccaa57e42f6a8930b18bafb493ea6349395b8/c=0-109-3373-2015/local/-/media/2015/09/08/USATODAY/USATODAY/635773470065997560-GTY-487308536.jpg',
    linkTo: '/map-view',
  }
]
export default function CategoriesSection() {
  const [companies, setCompanies] = useState([])
  const [locations, setLocations] = useState([])
  useEffect(() => {
    database.ref("categories").once("value", (snapshot) => {
      if (snapshot.exists()) {
        const items = [];
        snapshot.forEach((childSnapshot) => {
          var childData = childSnapshot.val();
          items.push(childData);
        });
        setCompanies(items)
      }
    }).catch((error) => {
      console.error(error);
    });
    database.ref("locations").once("value", (snapshot) => {
      if (snapshot.exists()) {
        const items = [];
        snapshot.forEach((childSnapshot) => {
          var childData = childSnapshot.val();
          items.push(childData);
        });
        setLocations(items)
      }
    }).catch((error) => {
      console.error(error);
    });
  }, [])
  return (
    <div>
      {/* <p style={{ color: '#fff', fontSize: '1.1em', fontWeight: '600', margin: '0px' }}>Top Locations</p>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          width: 'calc(100vw - 20px)',
          overflow: 'scroll',
          padding: '10px 5px'
        }}>
          {
            locations.length !== 0 && locations.map((location) => {
              return (
                <Col sm={12} md={4} lg={4} style={{ maxWidth: 'min-content', padding: '0', height: '150px' }}>
                  <Link to={{ pathname: `${location.linkTo}`, state: { fromDashboard: true, lng: location.coordinates.lng, lat: location.coordinates.lat } }}>
                    <Card className="category-cards" >
                      <Card.Img variant="top" src={location.imgURL} className="category-img" style={{ height: '100%', width: '100%', borderRadius: '10px' }} />
                      <Card.Body style={{ position: 'absolute', bottom: '0', width: '100%', backgroundImage: 'linear-gradient(360deg, black, transparent)', borderRadius: '10px' }}>
                        <p style={{ fontWeight: 'normal', margin: '0', color: 'white', letterSpacing: '2px' }}>{location.title}</p>
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
              )
            })
          }
        </div> */}
      <p style={{ color: '#fff', fontSize: '1.1em', fontWeight: '600', margin: '0px' }}>Browse by category</p>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        width: 'calc(100vw - 20px)',
        overflow: 'scroll',
        margin: '10px 0px',
        paddingBottom: 0,
        maxHeight: '275px'
      }}>
        {
          companies && companies.map((type) => {
            return (
              <Link to={{ pathname: `${type.linkTo}`, state: { fromDashboard: true, category: type.name } }}>
                <Card className="category-cards" >
                  <Card.Img variant="top" src={type.url} className="category-img" style={{ height: '180px', width: '100%', borderRadius: '10px', borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }} />
                  <Card.Body style={{ width: '100%', borderRadius: '10px' }}>
                    <p style={{ fontWeight: 'normal', margin: '0', color: '#fff', letterSpacing: '1px', fontWeight: '500', minHeight: '50px', paddingRight: '30px' }}>{type.name}</p>
                  </Card.Body>
                </Card>
              </Link>
            )
          })
        }
      </div>
      <p style={{ color: '#fff', fontSize: '1.1em', fontWeight: '600', margin: '0px' }}>Featured organizations</p>

    </div>
  );
}
