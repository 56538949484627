import React, { useState, useEffect, useRef } from "react";
import { Form, Col, Button, Card, Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { Redirect } from 'react-router-dom'
import "react-toastify/dist/ReactToastify.css";
import { database, storage } from "../config";
import firebase from 'firebase'
import { IoAddCircleOutline } from 'react-icons/io5';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import UploadContainer from '../Components/Upload/UploadContainer'
import { Button as MaterialBtn } from "@material-ui/core";
import SelectLocation from "../Components/map/SelectLocation";
import OnlyMap from "../Components/map/OnlyMap";
import { categories } from '../Components/CategoriesSection'


export default function BecomeHost() {
  const [profileCheck, setProfileCheck] = useState(null)
  const [profileData, setProfileData] = useState([])
  const [companies, setCompanies] = useState([])

  //form submission
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("")
  const [category, setCategory] = useState("");
  const [lngLat, setLngLat] = useState({ lng: -0.11, lat: 51.49 })
  const [address, setAddress] = useState("");
  const [title, setTitle] = useState("");
  const [about, setAbout] = useState("")
  const [sellingPrice, setSellingPrice] = useState("");
  const [link, setLink] = useState('')
  const [accountLink, setAccountLink] = useState('')

  const [userUid, setUserUid] = useState("")
  const [videoProgressPercent, setVideoProgressPercent] = useState(0)
  const videoRef = useRef(null)
  const [videoOneURL, setVideoOneURL] = useState("");
  const [imageOneURL, setImageOneURL] = useState("");
  const [imageTwoURL, setImageTwoURL] = useState("");
  const [imageThreeURL, setImageThreeURL] = useState("");
  const [imageFourURL, setImageFourURL] = useState("");
  //progress status 
  //form submit status
  const [submit, setSubmit] = useState("")
  //Authstate
  const [authState, setAuthState] = useState("");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (key) => {
    setShow(true);
  }
  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (!user) {
        setAuthState("Logged-out")
      } else {
        setAuthState("Logged-in")
        setUserUid(user.uid)
        setEmail(user.email)
        setName(user.displayName)
        database.ref("categories").once("value", (snapshot) => {
          if (snapshot.exists()) {
            const items = [];
            snapshot.forEach((childSnapshot) => {
              var childData = childSnapshot.val();
              items.push(childData);
            });
            setCompanies(items)
          }
        }).catch((error) => {
          console.error(error);
        });
        database.ref("My-Profile").orderByChild("userUid").equalTo(user.uid).once("value", (snapshot) => {
          if (snapshot.exists()) {
            setProfileCheck(true)
          } else {
            setProfileCheck(false)
          }
        }).catch((error) => {
          console.error(error);
        });
        database.ref("My-Profile").orderByChild("userUid").equalTo(user.uid).on('value', (snapshot) => {
          const items = [];
          snapshot.forEach((childSnapshot) => {
            var childData = childSnapshot.val();
            items.push(childData);
          });
          setProfileData(items)
        });
      }
    });
  }, [])



  //video function
  async function uploadVideo(e) {

    const videoFile = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      videoRef.current.src = e.target.result
    }
    reader.readAsDataURL(videoFile)
    try {
      const compressedFile1 = videoFile
      await uploadToServer(compressedFile1)
      function uploadToServer() {
        const videoOne = compressedFile1;
        const uploadTask = storage.ref(`images/${videoOne.name}`).put(videoOne);
        uploadTask.on("state_changed",
          (snapshot) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
            setVideoProgressPercent(progress)
          },
          (error) => {
            alert(error)
          },
          () => {
          }
        )
        uploadTask.on(
          "STATE_CHANGED",
          (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            toast("Video is Uploading:Please Wait", { type: "warning", toastId: "1", });
            if (progress === 100) {
              toast.update("1", {
                render: "Video Upload Done",
                type: "success",
                autoClose: 1000
              });
            }
          },
          (error) => {
            console.log(error);
            toast(error, { type: "error" })
          },
          () => {
            storage
              .ref("images")
              .child(videoOne.name)
              .getDownloadURL()
              .then((url) => {
                setVideoOneURL(url);
              });
          }
        );
      }

    } catch (error) {
      toast(error, { type: "error" })
    }
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    if (imageOneURL === "") {
      console.log(
        imageOneURL, imageTwoURL, imageThreeURL, imageFourURL
      );
      toast("Provide an image of your program to continue", { type: "error" });
    } else if (lngLat === { lng: -0.11900928568397262, lat: 51.4955567743161 }) {
      alert('You did not set the location...kindly tap on the map to set/change location')
    } else {
      database.ref("requests").push({
        name: name,
        email: email,
        phoneNumber: phoneNumber,
        category: category,
        address: address,
        title: title,
        about: about,
        sellingPrice: sellingPrice,
        userUid: userUid,
        videoOneURL: videoOneURL,
        imageOneURL: imageOneURL,
        imageTwoURL: imageTwoURL,
        imageThreeURL: imageThreeURL,
        imageFourURL: imageFourURL,
        lngLat: lngLat,
        userData: profileData,
        link,
        accountLink
      }); 
      setSubmit("Submitted")
    }
  };

  //Redirect after form submission
  if (submit === "Submitted") {
    return (
      <>
        <Redirect to="/done-posting-home" />
      </>
    )
  }

  if (authState === "Logged-out") {
    return (
      <>
        <Redirect to="/" />
      </>
    )
  }
  if (profileCheck === false) {
    return (
      <>
        <Redirect to="/create-profile" />
      </>
    )
  }

  return (

    <div className="home_container" style={{ background: '#fff', padding: '0px 15px' }}>

      {/* Post form */}
      <ToastContainer
        position="top-right"
        autoClose={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />

      <Card className="become-host-card main" style={{ border: 'none' }}>

        {/* <Card.Header className="h3">   
        Become a Host
        </Card.Header> */}

        <Card.Body className="container" style={{ padding: '0px' }}>

          <h2 className="mt-3">Contact Details</h2>

          <Form onSubmit={handleSubmit}>
            <Form.Row>
              <Form.Group as={Col} controlId="formGriName">
                <Form.Label>Program/non-profit name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Your Program/non-profit's name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="E-mail"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Contact Number(optional)</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Phone number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)} />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formGridCategory">
                <Form.Label>Select type of organization</Form.Label>
                <Form.Control
                  as="select"
                  name="Fuel"
                  onChange={(e) => { setCategory(e.target.value) }}
                >
                  <option>Select</option>
                  {
                    companies.map(data => {
                      return (
                        <option value={data.name}>{data.name}</option>
                      )
                    })
                  }
                </Form.Control>
              </Form.Group>
            </Form.Row>
            <h2 className="mt-3">Location Details</h2>
            <Form.Row className="mb-3" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <Form.Group as={Col} controlId="formGridLocation">
                <div style={{ width: '-webkit-fill-available', height: '200px', display: 'flex', position: 'relative' }} onClick={() => { handleShow() }}>
                  <OnlyMap lngLat={lngLat} />
                  <div style={{ display: `${lngLat.lat === 51.49 ? 'flex' : 'none'}`, height: '100%', width: '100%', backgroundColor: '#00000080', alignItems: 'center', justifyContent: 'center', position: 'absolute', zIndex: '9' }}>
                    <p style={{ margin: '0', color: '#fff', position: 'absolute', maxWidth: '70%', fontSize: '1.5em', textAlign: 'center' }}>Tap Here To Set Location</p>
                  </div>
                </div>
              </Form.Group>
              <Form.Group as={Col} controlId="formGridCity">
                <Form.Label>Write Down Your Address (optional)</Form.Label>
                <Form.Control
                  placeholder="Eg. 425 Vale Rd. Brooklyn, NY 11228"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)} />
              </Form.Group>
              <Modal show={show} onHide={handleClose}>
                <SelectLocation handleClose={handleClose} setLngLat={setLngLat} />
              </Modal>
            </Form.Row>


            <h2 className="mt-3">Specific Details</h2>
            <Form.Group controlId="formGridAddress2">
              <Form.Label>Title</Form.Label>
              <Form.Control
                placeholder="Eg. we help out children in need"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required />
            </Form.Group>
            <Form.Group controlId="formGridAddress2">
              <Form.Label>About this organization</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="write a description about the organization here"
                value={about}
                onChange={(e) => setAbout(e.target.value)}
                required />
            </Form.Group>
            <Form.Row>
              <Form.Group
                as={Col}
                lg={3}
                md={3}
                sm={12}
                controlId="formGridZip"
              >
                <Form.Label>Goal for donation</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Eg. 15,000"
                  value={sellingPrice}
                  onChange={(e) => setSellingPrice(e.target.value)}
                />
              </Form.Group>
            </Form.Row>
            <Form.Label>Upload a short video</Form.Label>
            <br />
            <Form.Row>
              <video ref={videoRef} controls autoPlay loop style={{ width: 'calc(100% - 20px)', margin: '0px 10px', display: `${videoProgressPercent !== 0 ? 'flex' : 'none'}` }}></video>
              <Form.Group as={Col} lg={3} md={3} sm={3} className="file-input" style={{ display: 'flex', justifyContent: 'center' }}>
                <Form.Control type="file" onChange={uploadVideo} required />
                <div style={{ padding: '15px 0px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                  <MaterialBtn variant="contained" style={{ backgroundColor: '#007bff', width: 'max-content', marginRight: `${videoProgressPercent !== 0 ? '16px' : '0px'}` }}>
                    <IoAddCircleOutline style={{ fontSize: '1.5em', marginRight: '10px' }} />Add Video
                  </MaterialBtn>
                  <div style={{ width: '50px', height: '50px', display: `${videoProgressPercent !== 0 ? 'flex' : 'none'}` }}>
                    <CircularProgressbar value={videoProgressPercent} text={`${videoProgressPercent}%`} />
                  </div>
                </div>
              </Form.Group>
            </Form.Row>
            <Form.Label>Upload Images</Form.Label>
            <UploadContainer
              setImageOneURL={setImageOneURL}
              setImageTwoURL={setImageTwoURL}
              setImageThreeURL={setImageThreeURL}
              setImageFourURL={setImageFourURL}
            />
            <Form.Group controlId="formGridAddress2" style={{ marginTop: '20px' }}>
              <Form.Label>Website/social media link</Form.Label>
              <Form.Control
                placeholder="Enter your social media link here"
                value={link}
                onChange={(e) => setLink(e.target.value)}
                required />
            </Form.Group>
            <Form.Group controlId="formGridAddress2">
              <Form.Label>Payment account link</Form.Label>
              <Form.Control
                placeholder="Enter your payment account link here"
                value={accountLink}
                onChange={(e) => setAccountLink(e.target.value)}
                required />
            </Form.Group>
            <Button variant="primary" className="btn btn-block" style={{ marginBottom: '60px' }} type="submit" onClick={() => { console.log(lngLat) }}>
              Post
            </Button>
          </Form>
        </Card.Body>
      </Card>

    </div>
  );
}
