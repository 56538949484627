import React, { useEffect } from "react";
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxGeocoder from 'mapbox-gl-geocoder'
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import TopBar from "../TopBar";
import { useLocation } from "react-router-dom";
import { Button } from "@material-ui/core";
import { getWindowDimensions } from "../Video";


const SelectLocation = (props) => {
    mapboxgl.accessToken = 'pk.eyJ1IjoibWVudXRvayIsImEiOiJjbGMzeG1kNHgwbm9lNDFuemkzaXduazgxIn0.alLoPOMYiPcZtHmA9EaLZQ';

    const location = useLocation();
    useEffect(() => {
        const linkParams = location.state?.lng ? [
            location.state.lng,
            location.state.lat,
        ] : [4.380197, 50.826758]
        const map = new mapboxgl.Map({
            container: "selectMapContainer",
            style: "mapbox://styles/mapbox/streets-v11",
            center: linkParams,
            zoom: 2,
        });
        const geolocate = new mapboxgl.GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true
            },
            trackUserLocation: true
        });
        map.addControl(geolocate, "top-left")
        const geocoder = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl,
            marker: true,
            hideOnSelect: true,
        });
        map.addControl(geocoder);



        const marker = new mapboxgl.Marker({ color: 'red', draggable: true })
            .setLngLat([4.380197, 50.826758])
            .addTo(map)

        map.on('click', (e) => {
            marker.setLngLat(e.lngLat)
            props.setLngLat({ lng: e.lngLat.lng, lat: e.lngLat.lat })
            console.log(e.lngLat);
        });
    }, []);
    return (
        <div id="selectMapContainer" className="map"
            style={{
                height: getWindowDimensions().height - 70, width: '-webkit-fill-available'
            }}
        >
            <div
                style={{ display: 'flex', width: '-webkit-fill-available', height: 'min-content', padding: '0px', margin: '10px', position: 'absolute', zIndex: '99', right: 0, bottom: 0 }} >
                <Button variant="contained"
                    style={{ width: '100%', padding: '10px 20px', backgroundColor: '#4c4', fontWeight: 'bold' }}
                    onClick={() => { props.handleClose() }}
                >
                    SELECT
                </Button>
            </div>
        </div>
    );
};

export default SelectLocation;