import React, { useEffect, useState } from "react";
import firebase from "firebase";
import Video from "../Components/Video";
import { database } from "../config";
import SingleProperty from "../pages/SinglePropertyPage";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Redirect } from "react-router-dom";
import { HiInformationCircle, HiX } from "react-icons/hi";
import { Modal } from "react-bootstrap";

function Home() {
  const [authState, setAuthState] = useState("");
  const [userUid, setUserUid] = useState(null);
  const [isPopupShown, setIsPopupShown] = useState(false);
  const [listingsCheck, setListingsCheck] = useState(null);
  const [key, setKey] = useState("");
  const [title, setTitle] = useState("");
  //snapshots
  const [listings, setListings] = useState([]);
  //spinner
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (key) => {
    setShow(true);
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (!user) {
        setAuthState("Logged-out");
      } else {
        setAuthState("Logged-in");
        setUserUid(user.uid);
      }
    });
  }, []);

  useEffect(() => {
    database
      .ref("organizations_posts")
      .once("value", (snapshot) => {
        if (snapshot.exists()) {
          setListingsCheck(true);
          {
            setLoading(false);
          }
        } else {
          setListingsCheck(false);
          {
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userUid]);
  //

  //get listing data
  useEffect(() => {
    if (listings.length === 0) {
      database
        .ref("organizations_posts")
        .limitToLast(10)
        .on("value", (snapshot) => {
          const items = [];
          snapshot.forEach((childSnapshot) => {
            var childKey = childSnapshot.key;
            var data = childSnapshot.val();
            console.log();
            items.push({ key: childKey, ...data });
          });
          setListings(items.reverse());
          console.log(items);
        });
    }
  }, [userUid]);

  const [muted, setMuted] = useState(true);

  if (authState === "Logged-out") {
    return (
      <>
        <Redirect to="/" />
      </>
    );
  }
  return (
    <div className="home_container">
      {loading === true ? (
        <div className="sk-cube-grid">
          <div className="sk-cube sk-cube1"></div>
          <div className="sk-cube sk-cube2"></div>
          <div className="sk-cube sk-cube3"></div>
          <div className="sk-cube sk-cube4"></div>
          <div className="sk-cube sk-cube5"></div>
          <div className="sk-cube sk-cube6"></div>
          <div className="sk-cube sk-cube7"></div>
          <div className="sk-cube sk-cube8"></div>
          <div className="sk-cube sk-cube9"></div>
        </div>
      ) : (
        ""
      )}
      <div
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          zIndex: "999",
          padding: "15px",
          display: `${isPopupShown ? "none" : "flex"}`,
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <span>
          <h4
            style={{
              margin: "0",
              color: "#fff",
              fontWeight: "500",
              textAlign: "left",
            }}
          >
            GIFTYARDZ
          </h4>
          <h6
            style={{
              margin: "0",
              color: "#fff",
              fontWeight: "500",
              textAlign: "left",
            }}
          >
            Where we socially market programs & non-profit organizations!!
          </h6>
        </span>
        <HiInformationCircle
          style={{ fontSize: "2em", color: "#fff" }}
          onClick={() => handleShow()}
        />
      </div>
      <Modal show={show} onHide={handleClose}>
        <div style={{ padding: "10px 10px" }}>
          <h4
            style={{
              margin: "0",
              color: "#fff",
              fontWeight: "500",
              textAlign: "left",
              marginBottom: "30px",
            }}
          >
            GIFTYARDZ
          </h4>
          <h6
            style={{
              margin: "0",
              color: "#fff",
              fontWeight: "500",
              textAlign: "left",
              marginBottom: "30px",
            }}
          >
            Where we socially market programs & non-profit organizations!!
          </h6>
          <h6
            style={{
              margin: "0",
              color: "#fff",
              fontWeight: "500",
              textAlign: "left",
              marginBottom: "20px",
            }}
          >
            GIFTYARDZ is a next generation platform for non profit
            organizations. We provide the best in class experience for people to
            find non-profit organizations and give donations to them.
          </h6>
          <h6
            style={{
              margin: "0",
              color: "#fff",
              fontWeight: "500",
              textAlign: "left",
              marginBottom: "30px",
            }}
          >
            Contact us:{" "}
            <span style={{ color: "#007bff" }}>roymichelle172@gmail.com</span>
          </h6>
          <h6
            style={{
              margin: "0",
              color: "#fff",
              fontWeight: "500",
              textAlign: "left",
              marginBottom: "15px",
            }}
          >
            Important Links.
          </h6>
          <p style={{ margin: "0" }}>TEACHERS ONLY!!</p>
          <span style={{ color: "#007bff" }}>
            <a href="https://buy.stripe.com/8wM5mTbXVh1J6RO7su" target="_blank">
              https://buy.stripe.com/8wM5mTbXVh1J6RO7su
            </a>
          </span>
          <br></br>
          <br></br>
          <p style={{ margin: "0" }}>SCHOOL LUNCH DONATIONS ONLY!!</p>
          <span style={{ color: "#007bff" }}>
            <a href="https://buy.stripe.com/5kA9D9bXV26P2By6op" target="_blank">
              https://buy.stripe.com/5kA9D9bXV26P2By6op
            </a>
          </span>
        </div>
        <div
          onClick={() => {
            handleClose();
          }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            right: "10px",
            top: "10px",
            height: "50px",
            width: "50px",
            borderRadius: "25px",
            color: "#fff",
            backgroundColor: "#ffffff30",
          }}
        >
          <HiX style={{ fontSize: "2em" }} />
        </div>
      </Modal>
      <div style={{ display: `${isPopupShown ? "none" : "block"}` }}>
        {listings.length !== 0 &&
          listings.map((data, i) => {
            if (data.videoURL !== "") {
              return (
                <>
                  <Video
                    key={i}
                    pos={i}
                    data={data}
                    length={listings.length}
                    muted={muted}
                    setMuted={setMuted}
                    setIsPopupShown={setIsPopupShown}
                    setKey={setKey}
                    setTitle={setTitle}
                  />
                </>
              );
            }
          })}
      </div>
      {isPopupShown ? (
        <>
          <div
            style={{
              height: "max-content",
              width: "100vw",
              backgroundColor: "#fff",
            }}
          >
            <div className="topbar_container">
              <div
                onClick={() => {
                  setIsPopupShown(false);
                }}
              >
                <FontAwesomeIcon
                  icon={faAngleLeft}
                  style={{
                    padding: "0px 27px",
                    fontSize: "1.6em",
                    color: "#fff",
                  }}
                />
              </div>
              <h2
                style={{
                  color: "#fff",
                  fontSize: "1.1em",
                  fontWeight: "500",
                  margin: "0",
                }}
              >
                {title}
              </h2>
            </div>
            <SingleProperty
              pagekey={key}
              title={title}
              setIsPopupShown={setIsPopupShown}
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
export default React.memo(Home);
