import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col } from "react-bootstrap"; 

export default function SuccessfulPosting() {
    return (
        <>
            <Container className="mr-top-2">
                <Row>
                    <Col md={6} sm={12} lg={6}>
                        {/* <img src={successPic} fluid="true" className="done-img" /> */}
                    </Col>
                    <Col md={6} sm={12} lg={6} className="h4 align-self-center">
                        <div className="text-center successful-post-text">Your post is successfully sent to our team.<br></br>You will be contacted by us in short time.</div>
                    </Col>
                </Row>
                <center><Link to="/"><button className="btn btn-primary text-center mt-5">Go to Home</button></Link></center>
            </Container>
        </>
    )
}
