import firebase from 'firebase'
import "firebase/storage"

var firebaseConfig = {
  apiKey: "AIzaSyANNBUBT_416hlpY_fEij8QXQdgpk-gWeM",
  authDomain: "appgoat-donation.firebaseapp.com",
  projectId: "appgoat-donation",
  storageBucket: "appgoat-donation.appspot.com",
  messagingSenderId: "670884331049",
  appId: "1:670884331049:web:4c30551932128f855f6638"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

var database = firebase.database();

const auth = firebase.auth();

export const storage = firebase.storage();

export { auth };

export { database };
