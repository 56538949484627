import React, { useEffect, useState, useRef } from 'react';
import CategoriesSection from '../Components/CategoriesSection';
import MyListings from '../Components/featuredSection';
import CreateSmallMap from '../Components/map/CreateSmallMap';
import { Redirect } from "react-router-dom";
import firebase from "firebase";
import { database } from "../config";
import { BiSearchAlt } from 'react-icons/bi'
import { IoCloseSharp } from 'react-icons/io5'
function Explore() {
    const [authState, setAuthState] = useState("");
    const [lngLat, setLngLat] = useState({ lng: -73.85939300188882, lat: 40.90526980021096 })
    const [q, setQ] = useState('')
    useEffect(() => {
        firebase.auth().onAuthStateChanged(function (user) {
            if (!user) {
                setAuthState("Logged-out")
            } else {
                setAuthState("Logged-in")
                database.ref("My-Profile").orderByChild("userUid").equalTo(user.uid).on('value', (snapshot) => {
                    if (snapshot.exists) {
                        let location = [];
                        snapshot.forEach((child) => {
                            location.push(child.val()?.lngLat)
                        })
                        if (location[0]) {
                            setLngLat(location[0])
                        } else {
                            setLngLat({ lng: -73.85939300188882, lat: 40.90526980021096 })
                        }
                    } else {
                        setLngLat({ lng: -73.85939300188882, lat: 40.90526980021096 })
                    }
                });
            }
        });
    }, []);
    if (authState === "Logged-out") {
        return (
            <>
                <Redirect to="/" />
            </>
        )
    } else {
        return (
            <div className='explore_container'>
                <div style={{ width: '-webkit-fill-available', height: 'max-content', marginTop: '15px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '10px 0px', borderRadius: '50vh', backgroundColor: '#202020', height: '50px', width: '-webkit-fill-available' }}>
                        <BiSearchAlt style={{ color: '#007bff', fontSize: '30px', marginLeft: '10px' }} />
                        <input
                            type='text'
                            className='asdasdas'
                            placeholder='Search an organization...'
                            value={q}
                            onChange={(e) => setQ(e.target.value)}
                            style={{ flex: 1, height: '-webkit-fill-available', fontSize: '20px', background: 'none', color: '#fff' }}
                        />
                        {
                            q !== "" && <IoCloseSharp
                                onClick={() => { setQ('') }}
                                style={{ color: '#007bff', fontSize: '30px', marginRight: '10px' }} />
                        }
                    </div>
                </div>
                {
                    q === '' &&
                    <>
                        <div className='small_map_container'>
                            <p style={{ color: '#fff', fontSize: '1.1em', fontWeight: '600', marginBottom: '0px', marginTop: '10px' }}>Find Organizations Near You</p>
                            <div className='small_map' style={{ margin: '10px 0px' }}>
                                {
                                    lngLat && <CreateSmallMap lngLat={lngLat} />
                                }
                            </div>
                        </div>
                        <CategoriesSection />
                    </>
                }
                <MyListings q={q} />
            </div>
        )
    }

}

export default Explore