import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import becomeHost from './pages/becomeHost';
import Signup from './pages/Signup'
import Home from './pages/Home'
import SuccessfulPosting from './pages/SuccessfulPosting'
import MyProfile from './pages/MyProfile'
import CreateProfile from './pages/CreateProfile'
import SingleProperty from './pages/SinglePropertyPage'
import ScrollToTop from './Components/ScrollToTop';
import CreateMap from './Components/map/CreateMap';
import BottomNav from './pages/BottomNav';
import Explore from './pages/Explore';
import Search from './pages/Search';
import LikedSaved from './pages/LikedSaved';


const routing = (
  <Router>
    <ScrollToTop />
    <Route exact path="/" component={App} />
    <Route exact path="/become-host" component={becomeHost} />
    <Route exact path="/signup" component={Signup} />
    <Route exact path="/home" component={Home} />
    <Route exact path="/done-posting-home" component={SuccessfulPosting} />
    <Route exact path="/my-profile" component={MyProfile} />
    <Route exact path="/create-profile" component={CreateProfile} />
    <Route exact path="/organization" component={SingleProperty} />
    <Route exact path="/home/car" component={SingleProperty} />
    <Route exact path="/map-view" component={CreateMap} />
    <Route exact path="/explore" component={Explore} />
    <Route exact path="/explore/Search" component={Search} />
    <Route exact path="/likedsaved" component={LikedSaved} />
    <BottomNav />
  </Router>
)

ReactDOM.render(routing,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
