import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { BiDonateHeart } from 'react-icons/bi';
import {
  Button,
  Carousel,
  Row,
  Col,
  Card,
  Form,
  Modal,
} from "react-bootstrap";
import {
  EmailShareButton,
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import firebase from "firebase";
import { database } from "../config";
import { toast } from "react-toastify";
import { FiSend } from 'react-icons/fi';
import ReadReviews from '../Components/ReadReviews'
import TopBar from "../Components/TopBar";
import OnlyMap from "../Components/map/OnlyMap";
import { IoMdCall } from 'react-icons/io'
import { MdEmail } from 'react-icons/md'
import { HiHeart, HiOutlineBookmark, HiOutlineHeart, HiOutlineChat, HiBookmark, HiX, HiInformationCircle } from 'react-icons/hi'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FaMapMarkerAlt } from "react-icons/fa";
import ReadComments from "../Components/ReadComments";

function SinglePropertyPage(props) {
  const [showVideoModel, setShowVideoModel] = useState(false)
  //Authstate
  const [authState, setAuthState] = useState(null);
  const [userUid, setUserUid] = useState(null);
  const [listings, setListings] = useState([]);
  //Booking form states 
  const [propertyKey, setPropertyKey] = useState("");
  const [hostUid, setHostUid] = useState("");
  const [submit, setSubmit] = useState("");
  //Review form states
  const [stars, setStars] = useState("")
  const [review, setReview] = useState("")
  const [profileData, setProfileData] = useState(null)
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false)

  const [likes, setLikes] = useState(0)
  const [comments, setComments] = useState(0)
  const [comment, setComment] = useState("")

  const [liked, setLiked] = useState(false)
  const [saved, setSaved] = useState(false)
  const [show, setShow] = useState(false);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (!user) {
        setAuthState(false);
      } else {
        const queryString = (props.pagekey ? props.pagekey : window.location.search);
        const RetrivedchildKey = (props.pagekey ? props.pagekey : queryString.substring(1));
        database.ref('User_likes').child(user.uid).orderByChild("liked").equalTo(RetrivedchildKey).on('value', (snapshot) => {
          snapshot.val() && setLiked(true)
        })
        database.ref('User_saved').child(user.uid).orderByChild("saved").equalTo(RetrivedchildKey).on('value', (snapshot) => {
          snapshot.val() && setSaved(true)
        })
        setAuthState(true);
        setUserUid(user.uid);
        database.ref("My-Profile").orderByChild("userUid").equalTo(user.uid).on('value', (snapshot) => {
          const items = [];
          snapshot.forEach((childSnapshot) => {
            var childData = childSnapshot.val();
            items.push(childData);
          });
          setProfileData(items)
        });
      }
    });
  }, []);
  const submitComent = (carKey) => {
    database.ref("comments").child(propertyKey).push({
      senderUid: userUid,
      carKey: carKey,
      comment: comment,
      name: profileData[0].name,
    });
    setComment("")
  };
  function addHttpPrefix(url) {
    if (!url?.startsWith('http://') && !url?.startsWith('https://')) {
        url = 'https://' + url;
    }
    return url;
}
  //get listing data
  useEffect(() => {
    //Retrive key from URL
    const queryString = (props.pagekey ? props.pagekey : window.location.search);
    const RetrivedchildKey = (props.pagekey ? props.pagekey : queryString.substring(1));
    setPropertyKey(RetrivedchildKey);
    database
      .ref("organizations_posts")
      .child(RetrivedchildKey)
      .once("value", function (snapshot) {
        const items = [];
        items.push(
          snapshot.val()
        );
        setListings(items);
      });
    database.ref('product_likes').child(RetrivedchildKey).on('value', (snapshot) => {
      snapshot.val() && setLikes(snapshot.val().like);
    })
    database.ref("comments").child(RetrivedchildKey).on("value", (snapshot) => {
      snapshot.val() && setComments(snapshot.numChildren());
    })
  }, [userUid]);
  //

  const submitReview = (e) => {
    e.preventDefault();
    database.ref("Reviews").push({
      userUid: userUid,
      propertyKey: propertyKey,
      hostUid: hostUid,
      stars: stars,
      review: review,
      name: profileData[0].name,
    });
    toast("Review has been successfullt posted", { type: "success" })
    document.getElementById("review-form").reset();
  };

  //Option values
  function handleChange(event) {
    setStars(event.target.value);
  }
  const handleClose = () => setShow(false);
  const handleShow = (key) => {
    setShow(true);
  }

  //Redirect after form submission
  if (submit === "Submitted") {
    return (
      <>
        <Redirect to="/done-booking" />
      </>
    );
  }
  return (
    <div className="home_container" style={{ backgroundColor: '#151515', paddingbottom: '60px', paddingTop: '55px', height: 'calc(100vh - 60px)', width: '100vw' }}>
      {
        (
          !props.pagekey ?
            <TopBar title={listings && listings.length !== 0 ? `${listings[0].title}` : ""} />
            :
            <></>
        )
      }
      {listings.map((data) => (
        <div style={{ backgroundColor: '#151515' }}>
          <Modal className="single_page_video_modal" show={showVideoModel} style={{ zIndex: '9999999999', padding: '0' }}>
            <video
              autoPlay
              loop
              controls
              src={data.videoOneURL}
              id='video'
              style={{
                height: '-webkit-fill-available',
                width: '-webkit-fill-available',
                objectFit: 'fill'
              }}
            >
            </video>
            <div onClick={() => { setShowVideoModel(false) }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', right: '10px', top: '10px', height: '50px', width: '50px', borderRadius: '25px', color: '#fff', backgroundColor: '#00000080' }}>
              <HiX style={{ fontSize: '2em' }} />
            </div>
          </Modal>
          <Modal show={show} onHide={handleClose}>
            <div style={{ padding: '10px 10px' }}>
              <h4 style={{ margin: '0', color: '#fff', fontWeight: '500', textAlign: 'left', marginBottom: '20 px' }}>Giftyards</h4>
              <h6 style={{ margin: '0', color: '#fff', fontWeight: '500', textAlign: 'left' }}>Verified by us: <span style={{ color: '#007bff' }}>This organization is verified by our team. The provided contact informations are safe to contact.</span></h6>
              <h6 style={{ margin: '0', color: '#fff', fontWeight: '500', textAlign: 'left' }}>Contact us: <span style={{ color: '#007bff' }}>giftyards02@gmail.com</span></h6>
            </div>
            <div onClick={() => { handleClose() }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', right: '10px', top: '10px', height: '50px', width: '50px', borderRadius: '25px', color: '#fff', backgroundColor: '#ffffff30' }}>
              <HiX style={{ fontSize: '2em' }} />
            </div>
          </Modal>
          <Modal className='comment_section' animation={false} show={showReviewModal} onHide={() => { setShowReviewModal(false) }} style={{ paddingBottom: '20px' }}>
            <div className='topbar_container'>
              <div onClick={() => { setShowReviewModal(false) }}>
                <FontAwesomeIcon icon={faAngleLeft} style={{ padding: '0px 27px', fontSize: '1.6em', color: '#fff' }} />
              </div>
              <h2 style={{ color: '#fff', fontSize: '1.1em', fontWeight: '500', margin: '0' }}>
                Comment section
              </h2>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', padding: '10px' }}>
              <Form.Control type="text" value={comment} placeholder="Write here..." required onChange={(e) => setComment(e.target.value)} />
              <Button onClick={() => { comment !== "" && submitComent(propertyKey) }}>send</Button>
            </div>
            <ReadComments propertyKey={propertyKey} />
          </Modal>
          <div className="caraousel-slider mr-top-slider">
            <div>
              <Carousel>
                {
                  data.imageOneURL !== "" &&
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={data.imageOneURL}
                      alt="First slide"
                    />
                  </Carousel.Item>
                }
                {
                  data.imageTwoURL !== "" &&
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={data.imageTwoURL}
                      alt="Second slide"
                    />
                  </Carousel.Item>
                }
                {
                  data.imageThreeURL !== "" &&
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={data.imageThreeURL}
                      alt="Third slide"
                    />
                  </Carousel.Item>
                }
                {
                  data.imageFourURL !== "" &&
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={data.imageFourURL}
                      alt="Third slide"
                    />
                  </Carousel.Item>
                }
              </Carousel>
            </div>
          </div>
          <Modal className='share' animation={false} show={showShareModal} onHide={() => { setShowShareModal(false) }}  >
            <div style={{ display: 'flex', flexDirection: 'row', padding: '10px', justifyContent: 'space-between' }}>
              <EmailShareButton subject='check out this organixation' body={"https://giftyards.netlify.app/organixation?" + propertyKey} ><EmailIcon size={50} round={true} style={{ margin: '5px' }} /></EmailShareButton>
              <FacebookShareButton quote={'check out this organixation'} url={"https://giftyards.netlify.app/organixation?" + propertyKey} hashtag="#giftyards" ><FacebookIcon size={50} round={true} style={{ margin: '5px' }} /></FacebookShareButton>
              <TelegramShareButton title={'check out this organixation'} url={"https://giftyards.netlify.app/organixation?" + propertyKey} ><TelegramIcon size={50} round={true} style={{ margin: '5px' }} /></TelegramShareButton>
              <TwitterShareButton title={'check out this organixation'} url={"https://giftyards.netlify.app/organixation?" + propertyKey} hashtags={['#giftyards']} ><TwitterIcon size={50} round={true} style={{ margin: '5px' }} /></TwitterShareButton>
              <WhatsappShareButton title={'check out this organixation'} url={"https://giftyards.netlify.app/organixation?" + propertyKey}><WhatsappIcon size={50} round={true} style={{ margin: '5px' }} /></WhatsappShareButton>
            </div>
          </Modal>
          <div>
            <Col lg={8} md={8} sm={12} >
              <Card style={{ border: 'none', background: '#151515', color: '#fff' }}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

                  <div style={{ width: '100%', color: '#fff' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                      {
                        userUid && (propertyKey !== "") ?
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {liked ?
                              <HiHeart style={{ fontSize: '30px', color: 'red', marginRight: '5px' }}
                                onClick={async (e) => {
                                  await database.ref('product_likes').child(propertyKey).set({
                                    like: firebase.database.ServerValue.increment(-1)
                                  })
                                  database.ref('User_likes').child(userUid).orderByChild("liked").equalTo(propertyKey).on('value', (snapshot) => {
                                    snapshot.val() && database.ref('User_likes').child(userUid).child(Object.keys(snapshot.val())[0]).set({})
                                  })
                                  console.log("#########UNLIKED#########");
                                  setLiked(false)
                                }} ></HiHeart>
                              :
                              <HiOutlineHeart style={{ fontSize: '30px', marginRight: '5px' }}
                                onClick={async (e) => {
                                  await database.ref('product_likes').child(propertyKey).set({
                                    like: firebase.database.ServerValue.increment(1)
                                  })
                                  await database.ref('User_likes').child(userUid).push({
                                    liked: propertyKey,
                                    product: { key: propertyKey, ...data }
                                  })
                                  console.log("#########LIKED#########");
                                  setLiked(true)
                                }} />
                            }
                            <HiOutlineChat
                              style={{ marginLeft: '15px', fontSize: '30px', color: '#fff', marginRight: '5px' }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setShowReviewModal(true)
                              }}
                            />
                            {comments}

                            <FiSend style={{ fontSize: '27px', color: '#fff', marginLeft: '20px' }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setShowShareModal(true)
                              }} />
                            <div style={{ flex: '1' }}></div>
                            {
                              saved ?
                                <HiBookmark style={{ fontSize: '30px', color: '#fff' }}
                                  onClick={async (e) => {
                                    e.stopPropagation();
                                    await database.ref('User_saved').child(userUid).orderByChild("saved").equalTo(propertyKey).on('value', (snapshot) => {
                                      snapshot.val() && database.ref('User_saved').child(userUid).child(Object.keys(snapshot.val())[0]).set({})
                                    })
                                    console.log("#########unsaved#########");
                                    setSaved(false)
                                  }} />
                                :
                                <HiOutlineBookmark style={{ fontSize: '30px', color: '#fff' }}
                                  onClick={async (e) => {
                                    e.stopPropagation();
                                    await database.ref('User_saved').child(userUid).push({
                                      saved: propertyKey,
                                      product: { key: propertyKey, ...data }
                                    })
                                    console.log("#########saved#########");
                                    setSaved(true)
                                  }} />
                            }
                          </div>
                          :
                          <></>
                      }
                      <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                          <>
                            <span >{likes}{' '}{likes > 0 ? 'Likes' : 'Like'}{' '}{data.address !== "" ? <><FontAwesomeIcon style={{ marginLeft: '10px' }} icon={faMapMarkerAlt}></FontAwesomeIcon> {String(data.address).slice(0, 20)}</> : <></>} </span>
                          </>
                          <span >{data.title}</span>
                        </div>
                        <div style={{ flex: '1', minWidth: '10px' }}></div>
                      </div>
                    </div>
                    <br></br>
                    <span style={{ fontSize: '1.3em', alignSelf: 'center', color: '#fff', borderRadius: '8px', padding: '2px 2px' }}>With a goal of ${Number(data.sellingPrice).toLocaleString('en')}</span>
                  </div>
                </div>
                <Button
                  onClick={() => { setShowVideoModel(true) }}
                  style={{ height: '44px', borderRadius: '22px', backgroundColor: '#42A5F530', padding: '7px 10px', border: 'none', color: '#42A5F5', marginTop: '10px' }}>
                  Watch Video
                </Button>
                <hr style={{ background: '#6d6d6d' }} />
                <div>
                  {
                    listings[0].lngLat.lat !== 51.49 ?
                      <>
                        <p style={{ color: 'black', fontSize: '1.6em', margin: '0px', color: '#fff', webkitTextStrokeWidth: 'thin', marginBottom: '10px' }}>Organization Location</p>
                        <div style={{ width: '100%', height: '200px', marginBottom: '20px' }}>
                          <Link to={{ pathname: `/map-view`, state: { fromDashboard: true, lng: data.lngLat.lng, lat: data.lngLat.lat } }}>
                            <OnlyMap lngLat={data.lngLat} />
                          </Link>
                        </div>
                      </>
                      :
                      <></>
                  }
                  {
                    data.link !== "" ?
                      <>
                        <p style={{ color: 'black', fontSize: '1.6em', margin: '0px', color: '#fff', webkitTextStrokeWidth: 'thin', marginBottom: '10px' }}>Organization website</p>
                        <div style={{ width: '100%', height: 'min-content', wordBreak: 'break-all', color: '#007bff', marginBottom: '20px', padding: '0px 10px' }}>
                          <a onClick={() => window.open(data.link, "_blank")}>{data.link}</a>
                        </div>
                      </>
                      :
                      <></>
                  }
                  <div style={{ width: '100%', display: 'flex' }}>
                    <p style={{ color: 'black', fontSize: '1.6em', margin: '0px', color: '#fff', webkitTextStrokeWidth: 'thin', marginBottom: '10px' }}>Contact Organization</p>
                    <div style={{ flex: '1' }} />
                    <HiInformationCircle style={{ fontSize: '2em', color: '#fff' }} onClick={() => { handleShow() }} />
                  </div>
                  <Row>
                    {
                      data.phoneNumber !== "" ?
                        <Col sm={12} lg={3} md={3} className="mb-1">
                          <IoMdCall style={{ fontSize: '1.2em', color: '#fff', marginRight: '5px' }} />{data.phoneNumber}
                        </Col>
                        : <></>
                    }
                    {
                      data.email !== "" ?
                        <Col sm={12} lg={3} md={3}>
                          <MdEmail style={{ fontSize: '1.2em', color: '#fff', marginRight: '5px' }} />{data.email}
                        </Col>
                        : <></>
                    }

                  </Row>
                  {/* <hr />
                  <Form onSubmit={submitReview} id="review-form">
                    <Form.Row>
                      <Form.Group
                        as={Col}
                        lg={8}
                        md={8}
                        sm={12}
                        controlId="formBasicText"
                      >
                        <p style={{ color: 'black', fontSize: '1.6em', margin: '0px', color: '#fff', webkitTextStrokeWidth: 'thin', marginBottom: '10px' }}>Write Your Review</p>
                        <Form.Control type="text" placeholder="Write here..." style={{ background: '#151515', color: '#fff' }} required onChange={(e) => setReview(e.target.value)} />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        lg={4}
                        md={4}
                        sm={12}
                        controlId="formBasicText"
                      >
                        <Form.Label>Rating</Form.Label>
                        <Form.Control
                          as="select"
                          name="category"
                          onChange={handleChange}
                          style={{ background: '#151515', color: '#fff' }}
                        >
                          <option>Select Stars</option>
                          <option value="5">5 Star</option>
                          <option value="4">4 Star</option>
                          <option value="3">3 Star</option>
                          <option value="2">2 Star</option>
                          <option value="1">1 Star</option>
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>

                    <Button variant="success" type="submit">
                      Post Review
                    </Button>
                  </Form>
                  <hr />
                  <ReadReviews propertyKey={propertyKey} /> */}
                </div>
              </Card>
            </Col>
            <br></br>
            <center>
              <a href={addHttpPrefix(data.accountLink)} target="_blank">
                <Button
                  style={{ borderRadius: 'calc(50vh)', display: 'flex', alignItems: 'center', backgroundColor: '#42A5F530', fontSize: '1.5em', padding: '7px 25px', border: 'none', color: '#42A5F5', marginTop: '10px' }}>
                  <BiDonateHeart style={{ color: '#42A5F5', marginRight: '10px', fontSize: '1.5em' }} />Donate
                </Button>
              </a>
            </center>
          </div>
          <br />
          <br />
        </div>
      ))
      }
    </div >
  );
}
export default React.memo(SinglePropertyPage)