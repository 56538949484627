import React, { useRef, useEffect, useState } from 'react';
import {
    Button,
    Col,
    Form,
    Modal
} from "react-bootstrap";
import {
    HiOutlineLocationMarker,
    HiVolumeUp,
    HiVolumeOff,
    HiHeart,
    HiOutlineChat,
    HiChevronRight,
    HiOutlineHeart
} from 'react-icons/hi';
import { FiSend } from 'react-icons/fi';
import OnlyMap from '../Components/map/OnlyMap'
import {
    faAngleLeft
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { database } from "../config";
import firebase from "firebase";
import ReadComments from './ReadComments';
import {
    EmailShareButton,
    FacebookShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";
import {
    EmailIcon,
    FacebookIcon,
    TelegramIcon,
    TwitterIcon,
    WhatsappIcon,
} from "react-share";
import { Link } from "react-router-dom";

export function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}
function Video(props) {
    const videoRef = useRef(null);
    const [showMuteLogo, setShowMuteLogo] = useState(false)
    function playPause() {
        props.setMuted(!props.muted)
        setShowMuteLogo(true)
        setTimeout(() => {
            setShowMuteLogo(false)
        }, 1500);
    }

    const [showLocationModal, setShowLocationModal] = useState(false);
    const [showReviewModal, setShowReviewModal] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false)
    const url = "https://giftyardz.netlify.app/car?" + props.data.key
    const [comment, setComment] = useState("")
    const [profileData, setProfileData] = useState(null)

    const [authState, setAuthState] = useState(null);
    const [userUid, setUserUid] = useState(null);
    const [liked, setLiked] = useState(false)
    const [totalLikes, setTotalLikes] = useState(0)
    const [totalComments, setTotalComments] = useState(0)
    useEffect(() => {
        firebase.auth().onAuthStateChanged(function (user) {
            if (!user) {
                setAuthState(false);
            } else {
                setAuthState(true);
                setUserUid(user.uid);
                database.ref("My-Profile").orderByChild("userUid").equalTo(user.uid).on('value', (snapshot) => {
                    const items = [];
                    snapshot.forEach((childSnapshot) => {
                        var childData = childSnapshot.val();
                        items.push(childData);
                    });
                    setProfileData(items)
                });
                database.ref('User_likes').child(user.uid).orderByChild("liked").equalTo(props.data.key).on('value', (snapshot) => {
                    snapshot.val() && setLiked(true)
                })
                database.ref('product_likes').child(props.data.key).on('value', (snapshot) => {
                    snapshot.val() && setTotalLikes(snapshot.val().like);
                })
                database.ref("comments").child(props.data.key).on("value", (snapshot) => {
                    snapshot.val() && setTotalComments(snapshot.numChildren());
                })
            }
        });
    }, []);


    const submitComent = (carKey) => {
        database.ref("comments").child(props.data.key).push({
            senderUid: userUid,
            carKey: carKey,
            comment: comment,
            name: profileData[0].name,
        });
        setComment("")
    };



    useEffect(() => {
        let componentmounted = true
        if (componentmounted) {
            let options = {
                rootMargin: "0px",
                threshold: [0.25, 0.75]
            };

            let handlePlay = (entries, observer) => {
                entries.forEach((entry) => {
                    if (componentmounted) {
                        if (entry.isIntersecting) {
                            videoRef.current.play();
                        } else {
                            videoRef.current.pause();
                        }
                    }
                });
            };
            let observer = new IntersectionObserver(handlePlay, options);
            observer.observe(videoRef.current);
        }
        return () => {
            componentmounted = false
        }
    });
    return (
        <div className='main-scroll-view-child' style={{
            marginBottom: `${props.pos === props.length - 1 ? '60px' : 0}`,
            position: 'relative', height: getWindowDimensions().height - 60
        }}>
            <video
                autoPlay
                ref={videoRef}
                loop
                src={props.data.videoOneURL}
                id='video'
                muted={props.muted}
                onClick={() => {
                    playPause()
                }}
                style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'fill'
                }}
            >
            </video>
            {
                showMuteLogo ?
                    <div style={{
                        height: 'min-content',
                        width: 'min-content',
                        color: 'white',
                        position: 'absolute',
                        bottom: 'calc(50% - 35px)',
                        left: 'calc(50% - 35px)'
                    }}>
                        {
                            props.muted === true ?
                                <div style={{
                                    height: '70px', width: '70px', padding: '5px', borderRadius: '50px',
                                    backgroundColor: '#000000b0', display: 'flex', justifyContent: 'center', alignItems: 'center'
                                }}>
                                    <HiVolumeOff style={{ fontSize: '30px' }} />
                                </div>
                                :
                                <div style={{
                                    height: '70px', width: '70px', padding: '5px', borderRadius: '50px',
                                    backgroundColor: '#000000b0', display: 'flex', justifyContent: 'center', alignItems: 'center'
                                }}>
                                    <HiVolumeUp style={{ fontSize: '30px' }} />
                                </div>
                        }
                    </div>
                    :
                    <></>
            }
            <div style={{
                height: '260px',
                width: '100%',
                color: 'white',
                position: 'absolute',
                bottom: '0px',
                left: 0,
                display: 'flex',
                flexDirection: 'row',
            }}
                onClick={() => {
                    playPause()
                }}
            >
                <div style={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    flex: '0.8',
                    justifyContent: 'end',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-end',
                    paddingLeft: '15px',
                    paddingBottom: '15px',
                    fontSize: '0.85em',
                }}
                >
                    <h4>With a goal of ${Number(props.data.sellingPrice).toLocaleString('en')}</h4>
                    <p style={{ marginBottom: '5px' }}>{props.data.location}</p>
                    <p style={{ marginBottom: '5px' }}>@{props.data.name}</p>
                    <button style={{
                        padding: '10px 20px',
                        backgroundColor: '#000000aa',
                        color: 'white',
                        borderRadius: '10px',
                        fontSize: '1.1em',
                        border: 'none'
                    }}
                        onClick={(e) => {
                            e.stopPropagation();
                            props.setKey(props.data.key)
                            props.setTitle(props.data.title)
                            props.setIsPopupShown(true)
                        }}
                    >Details <HiChevronRight /></button>
                </div>
                <div style={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    flex: '0.2',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                }}
                >
                    <HiOutlineLocationMarker
                        style={{ fontSize: '30px' }}
                        onClick={(e) => {
                            e.stopPropagation();
                            setShowLocationModal(true);
                        }} />
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        {
                            liked ?
                                <HiHeart style={{ fontSize: '30px', color: 'red' }}
                                    onClick={async (e) => {
                                        e.stopPropagation();
                                        await database.ref('car_likes').child(props.data.key).set({
                                            like: firebase.database.ServerValue.increment(-1)
                                        })
                                        await database.ref('User_likes').child(userUid).orderByChild("liked").equalTo(props.data.key).on('value', (snapshot) => {
                                            snapshot.val() && database.ref('User_likes').child(userUid).child(Object.keys(snapshot.val())[0]).set({})
                                        })
                                        console.log("#########UNLIKED#########");
                                        setLiked(false)
                                    }} ></HiHeart>
                                :
                                <HiOutlineHeart style={{ fontSize: '30px' }}
                                    onClick={async (e) => {
                                        e.stopPropagation();
                                        await database.ref('car_likes').child(props.data.key).set({
                                            like: firebase.database.ServerValue.increment(1)
                                        })
                                        await database.ref('User_likes').child(userUid).push({
                                            liked: props.data.key
                                        })
                                        console.log("#########LIKED#########");
                                        setLiked(true)
                                    }} />
                        }
                        <p style={{ color: '#fff', margin: '0px' }}>{totalLikes}</p>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <HiOutlineChat style={{ fontSize: '30px' }}
                            onClick={(e) => {
                                e.stopPropagation();
                                setShowReviewModal(true)
                            }} />
                        <p style={{ color: '#fff', margin: '0px' }}>{totalComments}</p>
                    </div>
                    <FiSend style={{ fontSize: '30px' }}
                        onClick={(e) => {
                            e.stopPropagation();
                            setShowShareModal(true)
                        }} />
                </div>
            </div>
            <Modal className='location_modal' animation={false} show={showLocationModal} onHide={() => { setShowLocationModal(false) }} >
                <div className='topbar_container' style={{ width: '100%' }}>
                    <div onClick={() => { setShowLocationModal(false); }}>
                        <FontAwesomeIcon icon={faAngleLeft} style={{ padding: '0px 27px', fontSize: '1.6em', color: '#fff' }} />
                    </div>
                    <h2 style={{ color: '#fff', fontSize: '1.1em', fontWeight: '500', margin: '0' }}>
                        Location
                    </h2>
                </div>
                <div style={{ height: 'calc(100vh - 140px)', width: '100%', background: '#000' }}>
                    <Link to={{ pathname: `/map-view`, state: { fromDashboard: true, lng: props?.data?.lngLat?.lng, lat: props?.data?.lngLat?.lat } }}>
                        <OnlyMap lngLat={props?.data?.lngLat} />
                    </Link>
                </div>
            </Modal>
            <Modal className='comment_section' animation={false} show={showReviewModal} onHide={() => { setShowReviewModal(false) }} style={{ paddingBottom: '20px' }}>
                <div className='topbar_container'>
                    <div onClick={() => { setShowReviewModal(false) }}>
                        <FontAwesomeIcon icon={faAngleLeft} style={{ padding: '0px 27px', fontSize: '1.6em', color: '#fff' }} />
                    </div>
                    <h2 style={{ color: '#fff', fontSize: '1.1em', fontWeight: '500', margin: '0' }}>
                        Comment section
                    </h2>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', padding: '10px' }}>
                    <Form.Control type="text" value={comment} placeholder="Write here..." required onChange={(e) => setComment(e.target.value)} />
                    <Button onClick={() => { comment !== "" && submitComent(props.data.key) }}>send</Button>
                </div>
                <ReadComments propertyKey={props.data.key} />
            </Modal>
            <Modal className='share' animation={false} show={showShareModal} onHide={() => { setShowShareModal(false) }}  >
                <div style={{ display: 'flex', flexDirection: 'row', padding: '10px', justifyContent: 'space-between' }}>
                    <EmailShareButton subject='check out this organization' body={url} ><EmailIcon size={50} round={true} /></EmailShareButton>
                    <FacebookShareButton quote={'check out this organization'} url={url} hashtag="#giftyardz" ><FacebookIcon size={50} round={true} /></FacebookShareButton>
                    <TelegramShareButton title={'check out this organization'} url={url} ><TelegramIcon size={50} round={true} /></TelegramShareButton>
                    <TwitterShareButton title={'check out this organization'} url={url} hashtags={['#giftyardz']} ><TwitterIcon size={50} round={true} /></TwitterShareButton>
                    <WhatsappShareButton title={'check out this organization'} url={url}><WhatsappIcon size={50} round={true} /></WhatsappShareButton>
                </div>
            </Modal>
        </div>
    )
}
export default React.memo(Video)